import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { LumiWidgetService } from '@kit/lumi-widget/service/lumi-widget.service';
import { DialogService } from '@kit/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { WindowRef } from '@common/window-service/window.service';
import { catchError, EMPTY, interval, map, takeWhile } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LumiToken } from '@kit/lumi-widget/lumi.interface';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-lumi-teleconsultation',
  templateUrl: './lumi-teleconsultation.component.html',
  styleUrls: ['./lumi-teleconsultation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LumiTeleconsultationComponent implements AfterViewInit, OnDestroy {
  constructor(
    private readonly lumiWidgetService: LumiWidgetService,
    private readonly renderer: Renderer2,
    private readonly destroyRef: DestroyRef,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly bootstrap: BootstrapService,
    private readonly windowRef: WindowRef,
    private readonly elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngAfterViewInit(): void {
    this.initWidget();

    // @ts-ignore
    this.windowRef.nativeWindow.window.lumi.tryAgainExpSession = () => this.initWidget();
  }

  ngOnDestroy(): void {
    const script = this.document.getElementById('iframeResizer');
    if (script) this.document.body.removeChild(script);
  }

  private initWidget(): void {
    this.lumiWidgetService.getLumiToken$()
      .pipe(
        catchError(() => {
          this.showErrorPopup();

          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((token: LumiToken) => {
        this.setIframeStyle();
        // @ts-ignore
        this.windowRef.nativeWindow.window.lumi.onError = () => this.showErrorPopup();
        this.lumiWidgetService.initWidget('teleconsultation', 'teleconsultation', token);
        this.initIframeResizing();
      });
  }

  private initIframeResizing(): void {
    interval(4000)
      .pipe(
        map(() => {
          const script = this.document.createElement('script');
          const iFrame: HTMLElement = this.elementRef.nativeElement.getElementsByTagName('iFrame')[0];

          if (!iFrame) return true;

          iFrame.id = 'widgetIframe'
          script.src = 'https://widget-staging.myhealthathand.com/js/iframeResizer.min.js';
          script.id = 'iframeResizer';
          script.onload = () => {
            const iFrameResize = (window as any).iFrameResize;
            if (iFrameResize) {
              iFrameResize({ log: false }, '#widgetIframe');
            }
          };
          this.document.body.appendChild(script);

          return false;
        }),
        takeWhile(Boolean),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private showErrorPopup(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('pages.LUMI_WIDGET.ERROR.SERVICE_UNAVAILABLE'),
      confirmTitle: 'OK',
    }).afterClosed$.subscribe( () => this.router.navigateByUrl(this.bootstrap.link.teleconsultation));
  }

  private setIframeStyle(): void {
    interval(300)
      .pipe(
        map(() => {
          const iFameLink = this.elementRef.nativeElement.getElementsByTagName('iFrame')[0];

          if (!iFameLink) return true;

          this.renderer.setStyle(iFameLink, 'border', '0px');
          this.renderer.setStyle(iFameLink, 'border-radius', '15px');

          return false;
        }),
        takeWhile(Boolean),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
