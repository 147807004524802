<section class="article-list">
  <div class="shell">
    <div *ngIf="data.title" class="article-list__header">
      <h4 class="zero-margin h4 m-h2">{{ data.title }}</h4>
      <app-safe-html *ngIf="data.text"
                     class="t-small m-t-small"
                     [content]="data.text">
      </app-safe-html>
    </div>

    <ng-container *ngIf="articles" [formGroup]="filterForm">
      <div class="article-list__filters-block">
        <app-select class="article-list__filters-block-thematic"
                    selectKey="id"
                    viewKey="name"
                    [placeholder]="'pages.BLOG.PLACEHOLDERS.THEMATICS' | translate"
                    [options]="thematicsList$ | async"
                    formControlName="thematic">
        </app-select>

        <div class="flex-box">
          <span class="article-list__count">{{ 'pages.BLOG.LABELS.ARTICLES_COUNT' | translate: {count: articles?.length || 0} }}</span>
          <app-select class="article-list__filters-block-sorting"
                      selectKey="id"
                      viewKey="label"
                      [placeholder]="'pages.BLOG.PLACEHOLDERS.SORT_BY' | translate"
                      [options]="sortOptions$ | async"
                      formControlName="sorting">
          </app-select>
        </div>
      </div>

      <div class="article-list__content">
        <app-article *ngFor="let article of articles | paginate: {
                       itemsPerPage: itemOnPage,
                       currentPage: currentPage,
                       totalItems: articles.length
                     }; trackBy: trackBy"
                     class="article-list__content-item"
                     [article]="article"
                     [thematicLinks]="thematicLinks">
        </app-article>
      </div>

      <app-pagination [entities]="articles"
                      [entitiesOnPage]="itemOnPage"
                      [entitiesTotalElements]="articles.length"
                      (changePage)="onPageNumberChange($event)">
      </app-pagination>
    </ng-container>
  </div>
</section>
