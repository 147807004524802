<section class="section">
  <div class="section__shell shell">
    <div class="section_info">
      <h4 class="h4 m-h2 zero-margin">{{ data.title }}</h4>
      <p class="membership-promotion_sub-title text-large m-t-normal">{{ data.subTitle }}</p>

      <app-safe-html class="section__text text-normal" [content]="data.text"></app-safe-html>
    </div>
    <div class="cards">
      <div class="cards_item" *ngFor="let item of data.items" [ngClass]="[item.colour]">
        <div class="cards_header" *ngIf="item.title">
          <h5 class="h5 m-h3 zero-margin">{{ item.title }}</h5>
        </div>

        <app-safe-html class="section__text text-normal" *ngIf="item.text" [content]="item.text"></app-safe-html>
        <app-aem-button class="app-button" type="full-width" *ngIf="item.cta" [cta]="item.cta"></app-aem-button>
      </div>
    </div>
  </div>
</section>
