import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FlightInfo } from '@common/flight/flight.interfaces';
import { TripHero } from '@common/model/tripHero';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';

enum FlightSteps {
  ADD = 'add',
  SELECT = 'select',
  PREVIEW = 'preview',
}
@Component({
  selector: 'app-flight-add',
  templateUrl: './flight-add.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightAddComponent extends AemFragmentComponent {
  FlightSteps = FlightSteps;
  step: FlightSteps = FlightSteps.ADD;
  selectedFlight: FlightInfo;
  flights: FlightInfo[];

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Inject(AEM_DATA) data: TripHero,
  ) {
    super(data)
  }

  public selectFlight(flight: FlightInfo[]) {
    if (flight.length === 1) {
      this.selectedFlight = flight[0];
      this.step = FlightSteps.PREVIEW;
    } else if (flight.length > 1) {
      this.flights = flight;
      this.step = FlightSteps.SELECT;
    }

    this.cdr.markForCheck();
  }

  public goBack(resetFlights: boolean = false) {
    if (resetFlights) this.flights = [];

    this.step = this.step === FlightSteps.PREVIEW && this.flights?.length > 1 ? FlightSteps.SELECT : FlightSteps.ADD;
  }
}
