import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { catchError, filter, map, Observable, switchMap, take, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Policy,
  PolicyDetails,
  PolicyRequestData,
} from './policy.interface';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from '@kit/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerValidationError } from '@kit/server-errors/server-errors.interface';
import { DialogRef } from '@kit/dialog/dialog.interfaces';
import { response } from 'express';

@Injectable({ providedIn: 'root' })
export class ApiInsurancePolicyService {
  public readonly lastVerifiedPolicyDeferred = signal(false);
  public readonly lastVerifiedPolicyOpenPolicy = signal(false);
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    public readonly bootstrapService: BootstrapService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  public getPolicies(): Observable<Policy[]> {
    return this.http.get<Policy[]>(`${ this.baseUrl }/contracts`)
  }

  public getPolicyDetails$(contractNumber: string): Observable<PolicyDetails> {
    return this.http.get<PolicyDetails>(`${ this.baseUrl }/contracts/${ contractNumber }/details`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err?.status === 404) {
            this.showNotFoundErrorPopup();

            return throwError(err);
          }

          if (err?.status === 422 || err?.status === 449) {
            this.router.navigate(
              [this.bootstrapService.link.insuranceAddExistingPolicy],
              { queryParams: { contractNumber: btoa(contractNumber), reverification: true } }
            );

            return throwError(err);
          }

          this.showErrorPopUp();

          return throwError(err);
        })
      );
  }

  public getPolicyDetailsWithPin$(contractNumber: string, pinCode: string): Observable<PolicyDetails> {
    return this.http.get<PolicyDetails>(`${ this.baseUrl }/contracts/${ contractNumber }/details?pinCode=${ pinCode }`);
  }

  public deletePolicy$(contractNumber: string): Observable<void> {
    return this.showDeletionDialog().afterClosed$
      .pipe(
        filter(value => value === ConfirmationDialogComponent.CONFIRM),
        switchMap(() => this.http.delete<void>(`${ this.baseUrl }/contracts/${ contractNumber }`)),
        catchError((err) => {
          this.showDeletionErrorPopup();

          return throwError(err);
        }),
      );
  }

  public verifyContract$(policyRequestData: PolicyRequestData, forcedValidation: boolean = true): Observable<Policy> {
    const body: PolicyRequestData & { forcedValidation: boolean } = {} as any;
    this.lastVerifiedPolicyDeferred.set(false);
    this.lastVerifiedPolicyOpenPolicy.set(false);

    body.forcedValidation = forcedValidation;
    if (policyRequestData.contractNumber) body.contractNumber = policyRequestData.contractNumber;
    if (policyRequestData.pinCode) body.pinCode = policyRequestData.pinCode;
    if (policyRequestData.firstName) body.firstName = policyRequestData.firstName;
    if (policyRequestData.lastName) body.lastName = policyRequestData.lastName;
    if (policyRequestData.bookingReference) body.bookingReference = policyRequestData.bookingReference;
    if (policyRequestData.dateOfBirth) body.dateOfBirth = policyRequestData.dateOfBirth;

    return this.http.post<Policy>(`${ this.baseUrl }/contracts`, body, { observe: 'response' }).pipe(
      tap((response) => {
        this.lastVerifiedPolicyDeferred.set(response.body?.status === 'DEFERRED');
        this.lastVerifiedPolicyOpenPolicy.set(response.status === 202);
      }),
      map((response) => response.body),
      catchError((err: HttpErrorResponse) => throwError(this.updateErrorFieldName(err))),
    );
  }

  public sendPinToEmail$(contractNumber: string, target: string): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/contracts/${ contractNumber }/pin-validation`, {
      target,
    });
  }

  public getPinCode$(policyNumber: string, alreadyAdded: boolean): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/insurance-policy/register`, {
      policyNumber,
      alreadyAdded
    });
  }

  public showOpenPolicyPopUp(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_INSURANCE.TITLES.POLICY_UNAVAILABLE'),
      info: this.translateService.instant('pages.MY_INSURANCE.DESCRIPTIONS.POLICY_UNAVAILABLE'),
      confirmTitle: this.translateService.instant('OK'),
    });
  }

  private showErrorPopUp(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_INSURANCE.TITLES.SERVICE_UNAVAILABLE'),
      info: this.translateService.instant('pages.MY_INSURANCE.DESCRIPTIONS.SERVICE_UNAVAILABLE'),
      confirmTitle: this.translateService.instant('OK'),
    }).afterClosed$
      .subscribe(() => {
        if (this.isNavigationNeeded()) {
          this.router.navigate([this.bootstrapService.link.myInsurancePolicies]);
        }
      });
  }

  private showNotFoundErrorPopup(): void {
    const dialogRef = this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_INSURANCE.TITLES.POLICY_NOT_FOUND'),
      info: this.translateService.instant('pages.MY_INSURANCE.DESCRIPTIONS.POLICY_NOT_FOUND'),
      confirmTitle: this.translateService.instant('OK'),
    });

    const routerSubscription = this.router.events.pipe(
      take(1),
      tap(() => dialogRef.close('linkNavigation')),
    ).subscribe();

    dialogRef.afterClosed$.subscribe((arg: string) => {
      routerSubscription?.unsubscribe();

      if (arg !== 'linkNavigation' && this.isNavigationNeeded()) {
        this.router.navigate([this.bootstrapService.link.myInsurancePolicies])
      }
    });
  }

  private showDeletionDialog(): DialogRef<ConfirmationDialogComponent> {
    return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.TITLE'),
      info: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.MESSAGE'),
      importantInfo: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.INFO'),
      confirmTitle: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.CONFIRM'),
      cancelTitle: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.CANCEL'),
      reverseButtonPosition: true,
    })
  }

  private showDeletionErrorPopup(): DialogRef<ConfirmationDialogComponent> {
    return this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('errors.CEAZ006'),
      confirmTitle: this.translateService.instant('pages.MY_INSURANCE.DELETE_POLICY_DIALOG.OK'),
    })
  }

  private updateErrorFieldName(err: HttpErrorResponse): HttpErrorResponse {
    const errorWithUpdatedFieldName = err?.error?.fault?.errors.map((err: ServerValidationError) => {
      if (err.errorCode === 'CEAZ131' || err.errorCode === 'CEAZ121') {
        const editedError = { ...err };
        editedError.fieldName = null;

        return editedError;
      }

      return err;
    })

    return {
      ...err,
      error: {
        ...err.error,
        fault: {
          ...err.error.fault,
          errors: errorWithUpdatedFieldName
        }
      }
    }
  }

  private isNavigationNeeded(): boolean {
    const currentPath: string = this.activatedRoute.snapshot.firstChild.routeConfig.path;
    const navigationPath: string = this.bootstrapService.link.myInsurancePolicies.slice(1);

    return currentPath !== navigationPath;
  }
}
