<section class="section">
  <div class="section__shell shell">
    <div class="section__header" *ngIf="data.image">
      <img [src]="data.image.imagePath"
           [appImgAlt]="data.image.altText"
           [appImgAltData]="data">
    </div>
    <div class="section__body">
      <div class="service-cards">
        <div class="additional-service-card" *ngFor="let item of data.items">
          <div class="additional-service-card__body">
            <div class="additional-service-card__aside">
              <div class="h5 m-h3 additional-service-card__title">{{ item.title }}</div>
              <app-safe-html class="t-large m-t-normal additional-service-card__text additional-service-card__subtitle weight-bold"
                             [content]="item.subTitle">
              </app-safe-html>
              <p class="t-normal m-t-normal additional-service-card__text">{{ item.text }}</p>
            </div>
          </div>
          <div class="additional-service-card__footer">
            <app-aem-button *ngIf="item.cta1" [cta]="item.cta1" size="l"></app-aem-button>
            <app-aem-button *ngIf="item.cta2" [cta]="item.cta2" size="l"></app-aem-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
