<section>
  <div *let="selectedFolder$ | async as selectedFolder" class="section__shell shell">
    <div class="documents__header">
      <app-back-button (goBack)="goBack()" class="documents__back"></app-back-button>
      <div class="h1 m-h2" [ngClass]="selectedFolder ? 'documents__header-center' : 'documents__header-left'">{{ pageTitle$ | async }}</div>
    </div>
    <div *ngIf="vaultData$ | async as vaultData">
    <ng-container *let="vaultData.document as files">
      <ng-container *let="vaultData.folder as folders">
      <div class="documents__container">
        <ng-container *ngIf="selectedFolder; else defaultView">
          <app-document-vault-folder-page [folder]="selectedFolder" [isUserFreemium]="isUserFreemium"></app-document-vault-folder-page>
        </ng-container>

        <ng-template #defaultView>
          <app-safe-html [content]="'pages.DOCUMENT_VAULT.DESCRIPTIONS.DOCUMENTS_TEXT' | translate"
                         class="documents__text text-small">
          </app-safe-html>
          <div class="documents__section">
            <div *ngIf="isUserFreemium" class="documents__freemium">
              <app-icon icon="file-warning" theme="amped" size="large"></app-icon>
              <app-safe-html [content]="'pages.DOCUMENT_VAULT.DESCRIPTIONS.FREEMIUM_USER_DISCLAIMER' | translate"></app-safe-html>
            </div>
            <div class="documents__section-folders">
              <div class="documents__folders">
                  <ng-container *ngFor="let folder of folders?.content; trackBy: trackByFolder">
                    <app-document-vault-folder [folder]="folder" (click)="selectFolder(folder)"></app-document-vault-folder>
                  </ng-container>
              </div>
            </div>
            <div *ngIf="files?.totalElements" class="documents__section-files">
              <div class="weight-bold text-large documents__files-title">
                <div class="weight-bold text-large documents__section-title">{{ 'pages.DOCUMENT_VAULT.LABELS.TITLE_FILES' | translate }}</div>
                <div></div>
                <div class="weight-bold text-tiny documents__section-relative">{{ 'pages.DOCUMENT_VAULT.LABELS.TITLE_ADDED' | translate }}</div>
                <div></div>
              </div>
              <div class="documents__files">
                <ng-container *ngFor="let file of files.content| paginate: {
                              itemsPerPage: entitiesOnPage,
                              currentPage: currentPage,
                              totalItems: files.totalElements
                              }, trackBy: trackByFile">
                  <app-document-vault-file (changeCurrentPage)="changeCurrentPage($event)"
                                           [file]="file"
                                           [currentPage]="currentPage"
                                           [totalElements]="files?.totalElements"
                                           [pageSize]="entitiesOnPage">
                  </app-document-vault-file>
                </ng-container>
                <app-pagination [entities]="files.content"
                                [entitiesOnPage]="entitiesOnPage"
                                [entitiesTotalElements]="files.totalElements"
                                [theme]="'grey'"
                                (changePage)="getPage($event)"
                                class="documents__pagination">
                </app-pagination>
              </div>
            </div>
          </div>
          <div class="text-tiny m-t-small documents__disclaimer">
            <span>{{'pages.DOCUMENT_VAULT.FOOTER.FILES_ALLOWED' | translate}} </span>
            <span>{{'pages.DOCUMENT_VAULT.FOOTER.FILES_MAX_SIZE' | translate}} </span>
          </div>
        </ng-template>
        <div *ngIf="!isUserFreemium" class="documents__footer">
          <div class="documents__download" [ngClass]="{'documents__download-hide': selectedFolder}">
            <div class="documents__download-title weight-bold">
              {{'pages.DOCUMENT_VAULT.FOOTER.MOBILE_APPS_TITLE' | translate}}
            </div>
            <div class="documents__download-text text-small m-t-normal">
              {{'pages.DOCUMENT_VAULT.FOOTER.MOBILE_APPS_TEXT' | translate}}
            </div>
          </div>
          <div class="documents__buttons">
            <a [href]="appStoreLink" target="_blank" id="vault-appstore" class="documents__button">
              <img alt="download-app-store" class="documents__button-img" [src]="appStorePath">
            </a>
            <a [href]="googlePlayLink" target="_blank" id="vault-googleplay" class="documents__button">
              <img alt="download-google-play" class="documents__button-img google" [src]="googlePlayPath">
            </a>
          </div>
        </div>
      </div>
      </ng-container>
    </ng-container>
    </div>

  </div>
</section>
