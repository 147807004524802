<app-trip-hero-block (goBack)="goBack()">
  <div class="wallet-overview_form" [formGroup]="listForm">
    <h6 class="wallet-overview_form-title h6 m-h4">
      {{ 'forms.CO_TRAVELER.LABELS.TOTAL_FAMILY_AND_FRIENDS' | translate: { count: coTravelers.length } }}
    </h6>

    <div class="wallet-overview__list" formArrayName="users">
      <app-checkbox [formControlName]="i" *ngFor="let user of usersFormArray.controls; let i = index;">
        <div class="t-large m-t-normal">
          {{ coTravelers[i].name }} {{ coTravelers[i].surname }}
        </div>
      </app-checkbox>

      <app-button id="action-trip-add-cotravaler"
                  icon="plus"
                  theme="bordered"
                  class="wallet-overview__list-action"
                  *ngIf="!expired"
                  [appAemRouterLink]="bootstrapService.link.flightAddCotraveler"
                  [aemRouterQueryParams]="(returnToTripWallet ? { returnToTripWallet: true } : null)"
                  [aemRouterData]="{ tripId: tripId, flightId: flightId }"
                  size="inherit">
        {{ 'forms.CO_TRAVELER.ACTIONS.ADD_A_CO_TRAVELER' | translate }}
      </app-button>

      <div *ngIf="expired" class="error-message">{{expired}}</div>
    </div>

    <div class="wallet-overview__actions">
      <app-button (click)="submit()"
                  size="inherit"
                  id="action-trip-add-cotravaler-save"
                  *ngIf="!expired; else invalidDate"
                  type="full-width">
        {{ 'forms.CO_TRAVELER.ACTIONS.SAVE_AND_NEXT_STEP' | translate }}
      </app-button>
      <app-button (click)="goBack()"
                  theme="bordered"
                  size="inherit"
                  id="action-trip-add-cotravaler-cancel"
                  type="full-width">
        {{ 'forms.CO_TRAVELER.ACTIONS.CANCEL' | translate }}
      </app-button>
    </div>
  </div>
</app-trip-hero-block>

<ng-template #invalidDate>
  <app-button (click)="goBack()" type="full-width">
    {{ 'forms.CO_TRAVELER.ACTIONS.NEXT_STEP' | translate }}
  </app-button>
</ng-template>
