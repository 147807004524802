<header class="header">
  <div class="header__main">
    <div class="shell">
      <app-back-button *ngIf="data.hasBackButton"></app-back-button>
      <div class="header__brief">
        <div class="h1 m-h2 hero-header brief__title"
            *ngIf="data.title"
            [ngClass]="{'hero-header__centered': loungeVouchersPageTitles.includes(data.title.toLowerCase())}">
            {{ data.title | translate: { userName: userService.userName } }}
        </div>
        <div class="brief__group" *ngIf="data.text">
          <ng-container *ngIf="userData$ | async as userData">
            <app-safe-html
              class="brief__text m-t-normal"
              [content]="data.text | translate: {
              userMembership: userData.membershipStatus | appTranslateMembership,
              premiumExpirationDate: userData.premiumEnd | dateFormat: 'fullSpelledWithFullMonth'
            }"
            ></app-safe-html>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="extra-container" *ngIf="extra?.component">
  <ng-container *ngComponentOutlet="extra.component; injector: extra.injector"></ng-container>
</div>
