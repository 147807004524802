<section class="section">
  <div class="shell">
    <div class="succeeded">
      <ng-container *ngIf="!openPolicy() && !deferred()">
        <div class="succeeded_title h5 m-h3">
          {{
            reverification
              ? ('pages.MY_INSURANCE.TITLES.SUCCEEDED_REVERIFICATION_1' | translate)
              : ('pages.MY_INSURANCE.TITLES.SUCCEEDED_1' | translate)
          }}
          <a (click)="onViewPolicy()" class="succeeded_title_link">{{ policyCode }}</a>
          {{
            reverification
              ? ('pages.MY_INSURANCE.TITLES.SUCCEEDED_REVERIFICATION_2' | translate)
              : ('pages.MY_INSURANCE.TITLES.SUCCEEDED_2' | translate)
          }}
        </div>
        <div class="succeeded_text t-small m-t-normal">
          {{
            reverification
              ? ('pages.MY_INSURANCE.DESCRIPTIONS.SUCCEEDED_REVERIFICATION' | translate)
              : ('pages.MY_INSURANCE.DESCRIPTIONS.SUCCEEDED' | translate)
          }}
        </div>
        <div class="succeeded_buttons">
          <app-button *ngIf="showViewPolicyButton"
                      (click)="onViewPolicy()"
                      class="succeeded_button"
                      type="full-width">
            {{ 'pages.MY_INSURANCE.ACTIONS.VIEW_POLICY' | translate }}
          </app-button>
          <app-button [appAemRouterLink]="bootstrapService.link.myInsurancePolicies"
                      class="succeeded_button"
                      type="full-width"
                      theme="bordered">
            {{ 'pages.MY_INSURANCE.ACTIONS.CLOSE' | translate }}
          </app-button>
        </div>
      </ng-container>
      <ng-container *ngIf="openPolicy()" [ngTemplateOutlet]="openPolicyTemplate"></ng-container>
      <ng-container *ngIf="deferred()" [ngTemplateOutlet]="deferredTemplate"></ng-container>
    </div>
  </div>
</section>

<ng-template #openPolicyTemplate>
  <div class="succeeded_title h5 m-h3">{{ 'pages.MY_INSURANCE.TITLES.SUCCEEDED_OPEN_POLICY' | translate }}</div>
  <div class="succeeded_text t-small m-t-normal">
    <app-safe-html [content]="'pages.MY_INSURANCE.DESCRIPTIONS.SUCCEEDED_OPEN_POLICY' | translate"></app-safe-html>
  </div>
  <div class="succeeded_buttons">
    <app-button [appAemRouterLink]="bootstrapService.link.myInsurancePolicies"
                class="succeeded_button"
                type="full-width"
                theme="bordered">
      {{ 'pages.MY_INSURANCE.ACTIONS.CLOSE' | translate }}
    </app-button>
  </div>
</ng-template>
<ng-template #deferredTemplate>
  <div class="succeeded_title h5 m-h3">{{ 'pages.MY_INSURANCE.TITLES.SUCCEEDED_DEFERRED' | translate }}</div>
  <div class="succeeded_text t-small m-t-normal">
    <app-safe-html [content]="'pages.MY_INSURANCE.DESCRIPTIONS.SUCCEEDED_DEFERRED' | translate"></app-safe-html>
  </div>
  <div class="succeeded_buttons">
    <app-button [appAemRouterLink]="bootstrapService.link.myInsurancePolicies"
                class="succeeded_button"
                type="full-width"
                theme="bordered">
      {{ 'pages.MY_INSURANCE.ACTIONS.CLOSE' | translate }}
    </app-button>
  </div>
</ng-template>
