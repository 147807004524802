<section class="section">
  <div class="shell" [formGroup]="userDataForm">
    <div class="user-input">
      <div class="user-form">
        <div class="user-input_title h5 m-h3">{{ 'pages.MY_INSURANCE.TITLES.ENTER_USER_DATA' | translate }}</div>
        <div class="t-normal m-t-normal">{{ 'pages.MY_INSURANCE.DESCRIPTIONS.ENTER_USER_DATA' | translate }}</div>
        <app-label [label]="'pages.MY_INSURANCE.LABELS.USER_NAME' | translate"
                  [required]="true">
          <app-input formControlName="firstName"
                    [placeholder]="'pages.MY_INSURANCE.PLACEHOLDERS.FIRST_NAME' | translate"
                    [maxLength]="65">
          </app-input>
          <app-field-error controlName="firstName"></app-field-error>
        </app-label>
        <app-label [label]="'pages.MY_INSURANCE.LABELS.USER_LAST_NAME' | translate"
                  [required]="true">
          <app-input formControlName="lastName"
                    [placeholder]="'pages.MY_INSURANCE.PLACEHOLDERS.LAST_NAME' | translate"
                    [maxLength]="65">
          </app-input>
          <app-field-error controlName="lastName"></app-field-error>
        </app-label>
        <ng-container *ngIf="policyValidationParameter === 'bookingReference'">
          <app-label [label]="'pages.MY_INSURANCE.LABELS.BOOKING_REFERENCE' | translate"
                     [required]="true">
            <app-input [placeholder]="'pages.MY_INSURANCE.PLACEHOLDERS.BOOKING_REFERENCE' | translate"
                       formControlName="bookingReference"
                       [maxLength]="20">
            </app-input>
            <app-field-error controlName="bookingReference"></app-field-error>
          </app-label>
        </ng-container>
        <ng-container *ngIf="policyValidationParameter === 'dateOfBirth'">
          <app-label [label]="'pages.MY_INSURANCE.LABELS.USER_DATE_OF_BIRTH' | translate"
                     [required]="true">
            <app-date-picker formControlName="dateOfBirth"
                             [max]="today"></app-date-picker>
            <app-field-error controlName="dateOfBirth"></app-field-error>
          </app-label>
        </ng-container>

      </div>
      <app-field-error [control]="userDataForm" [isInBlock]="true"></app-field-error>
      <div class="user-input_buttons">
        <app-button class="user-input_button"
                    type="full-width"
                    [disabled]="disableButton()"
                    (click)="onSubmit()">
          {{ 'pages.MY_INSURANCE.ACTIONS.NEXT' | translate }}
        </app-button>
        <app-button class="user-input_button"
                    type="full-width"
                    [appAemRouterLink]="bootstrapService.link.myInsurancePolicies"
                    theme="bordered">
          {{ 'pages.MY_INSURANCE.ACTIONS.CANCEL' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</section>
