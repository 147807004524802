<div class="register-form" [formGroup]="registerForm">
  <h2 class="register-form__title">{{ title }}</h2>
  <div class="register-form__description text-small" *ngIf="description">
    {{ description }}
  </div>

  <div class="register-form__grid">
    <app-label [label]="'pages.REGISTRATION.LABELS.FIRST_NAME' | translate" [required]="true">
      <app-input [placeholder]="'pages.REGISTRATION.PLACEHOLDERS.FIRST_NAME' | translate"
                 id="register-first-name"
                 autocomplete="given-name"
                 inputName="first-name"
                 formControlName="firstName">
      </app-input>
      <app-field-error controlName="firstName"></app-field-error>
    </app-label>
    <app-label [label]="'pages.REGISTRATION.LABELS.LAST_NAME' | translate" [required]="true">
      <app-input [placeholder]="'pages.REGISTRATION.PLACEHOLDERS.LAST_NAME' | translate"
                 id="register-last-name"
                 autocomplete="family-name"
                 inputName="second-name"
                 formControlName="lastName">
      </app-input>
      <app-field-error controlName="lastName"></app-field-error>
    </app-label>
  </div>

  <app-label [label]="'pages.REGISTRATION.LABELS.MOBILE_NUMBER' | translate" [required]="!isAustrianMarket">
    <app-phone id="register-mobile-number"
               [placeholder]="'pages.REGISTRATION.PLACEHOLDERS.MOBILE_NUMBER' | translate"
               [disclaimer]="'controls.PHONE.DISCLAIMER' | translate"
               formControlName="phone">
    </app-phone>
    <app-field-error controlName="phone"></app-field-error>
  </app-label>

  <app-label [label]="'pages.REGISTRATION.LABELS.EMAIL' | translate" [required]="true">
    <app-input id="registrer-email"
               autocomplete="email"
               inputName="email"
               [placeholder]="'pages.REGISTRATION.PLACEHOLDERS.EMAIL' | translate" formControlName="email">
    </app-input>
    <app-field-error controlName="email"></app-field-error>
  </app-label>

  <app-label [label]="'pages.REGISTRATION.LABELS.PASSWORD' | translate" [required]="true">
    <app-password id="registration-password"
                  [autocomplete]="'new-password'"
                  [placeholder]="'pages.REGISTRATION.PLACEHOLDERS.PASSWORD' | translate"
                  formControlName="password">
    </app-password>
    <app-field-error controlName="password"></app-field-error>
  </app-label>
  <app-label [label]="'pages.REGISTRATION.LABELS.REPEAT_PASSWORD' | translate" [required]="true">
    <app-password id="registration-repeat-password"
                  [autocomplete]="'off'"
                  [placeholder]="'pages.REGISTRATION.PLACEHOLDERS.REPEAT_PASSWORD' | translate"
                  formControlName="repeatPassword">
    </app-password>
    <app-field-error controlName="repeatPassword"></app-field-error>
    <app-field-error [control]="registerForm"></app-field-error>
  </app-label>
  <app-password-security controlName="password"></app-password-security>

  <app-label [label]="'pages.REGISTRATION.LABELS.PROMOCODE' | translate">
    <app-input id="register-promocode"
               formControlName="promoCode"
               [placeholder]="'pages.REGISTRATION.PLACEHOLDERS.PROMOCODE' | translate"
    ></app-input>
    <app-field-error controlName="promoCode"></app-field-error>
  </app-label>

  <app-checkbox id="register-privacy-policy" formControlName="privacyPolicy">
    <app-safe-html [content]="'pages.REGISTRATION.PRIVACY_POLICY' | translate"></app-safe-html>
  </app-checkbox>
  <app-field-error controlName="privacyPolicy"></app-field-error>
  <app-checkbox *ngIf="isMarketingConsentGlobal; else marketingConsents"
                id="register-marketing-management-global"
                formControlName="marketingManagementGlobal">
    <app-safe-html [content]="'pages.REGISTRATION.MARKETING_MANAGEMENT_GLOBAL' | translate"></app-safe-html>
  </app-checkbox>
  <app-field-error controlName="marketingManagementGlobal"></app-field-error>

  <app-button
    id="action-create-account"
    icon="person-add"
    size="l"
    type="full-width"
    theme="black"
    [disabled]="registerForm.invalid"
    (click)="register()"
  >{{ 'pages.REGISTRATION.ACTIONS.CREATE_ACCOUNT' | translate }}</app-button>

  <div class="register-form__has-account">{{ 'pages.REGISTRATION.HAS_ACCOUNT' | translate }}</div>

  <app-login>
    <app-button
      id="action-log-in-instead"
      size="m"
      type="full-width"
      theme="bordered"
    >{{ 'pages.REGISTRATION.ACTIONS.LOG_IN_INSTEAD' | translate }}</app-button>
  </app-login>

  <ng-container *let="'pages.REGISTRATION.DESCRIPTION' | translate as description">
    <app-safe-html *ngIf="description" class="t-small m-t-tiny" [content]="description"></app-safe-html>
  </ng-container>
</div>

<ng-template #marketingConsents>
  <div [formGroup]="registerForm">
    <app-checkbox id="register-marketing-management-internal"
                  formControlName="marketingManagementInternal">
      <app-safe-html [content]="'pages.REGISTRATION.MARKETING_MANAGEMENT_INTERNAL' | translate"></app-safe-html>
    </app-checkbox>
  </div>
  <app-field-error controlName="marketingManagementInternal"></app-field-error>
  <div [formGroup]="registerForm">
    <app-checkbox id="register-marketing-management-external"
                  formControlName="marketingManagementExternal">
      <app-safe-html [content]="'pages.REGISTRATION.MARKETING_MANAGEMENT_EXTERNAL' | translate"></app-safe-html>
    </app-checkbox>
  </div>
</ng-template>
