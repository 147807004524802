import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicComponentAddInsuranceForm } from 'src/app/common/model/dynamicComponentAddInsuranceForm';
import { AemBaseBlockComponent } from 'src/app/kit/aem-base-block/aem-base-block';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import {
  MaskedEmail,
  PolicyPinCode,
  PolicyRequestData, PolicyValidationData,
  PolicyValidationInputs
} from '../../policy.interface';
import { EventDigitalData } from '@common/analytics/digital-data.interface';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { DOCUMENT } from '@angular/common';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';

enum PolicySteps {
  CODE = 'code',
  EMAIL = 'email',
  PIN = 'pin',
  USER_DATA = 'userData',
  SUCCESS = 'success',
}

@Component({
  selector: 'app-insurance-add-existing-policy',
  templateUrl: './insurance-add-existing-policy.component.html',
  styleUrls: ['./insurance-add-existing-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddExistingPolicyComponent extends AemBaseBlockComponent implements OnInit {
  public PolicySteps = PolicySteps;
  public step: PolicySteps = PolicySteps.CODE;
  public policyData: PolicyValidationData;
  public emails: MaskedEmail[];
  public contractNumber: string = null;
  public policyValidationParameter: keyof PolicyRequestData;
  public reverification = false;
  protected readonly PolicyValidationInputs = PolicyValidationInputs;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly analyticsService: AnalyticsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly bootstrapService: BootstrapService,
    @Inject(AEM_DATA) data: DynamicComponentAddInsuranceForm,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super(data);
  }

  public ngOnInit(): void {
    if (this.route.snapshot.queryParams.contractNumber) {
      this.contractNumber = atob(this.route.snapshot.queryParams.contractNumber);
    }

    if (this.route.snapshot.queryParams.reverification) {
      this.reverification = true;
    }
  }

  public saveCodeStep(policy: PolicyValidationData): void {
    this.policyData = policy;
    this.emails = policy.maskedEmails;
    this.contractNumber = policy.contractNumber;

    if (policy.maskedEmails.length === 0) {
      return this.changeStep(PolicySteps.SUCCESS);
    }

    if (policy.maskedEmails.length > 1) {
      return this.changeStep(PolicySteps.EMAIL);
    }

    if (policy.maskedEmails.length === 1) {
      return this.changeStep(PolicySteps.PIN);
    }
  }

  public handlePoliceValidationError(contractNumber: string, validationParameter: keyof PolicyRequestData): void {
    this.contractNumber = contractNumber;
    this.policyValidationParameter = validationParameter;

    this.changeStep(PolicySteps.USER_DATA);
  }

  public saveEmailStep(policy: PolicyValidationData): void {
    this.policyData = policy;
    this.changeStep(PolicySteps.PIN);
  }

  public savePinAndCodeStep(policy: PolicyPinCode): void {
    this.policyData = policy;

    this.successAddingHandler();
  }

  public saveUserDataStep(contractNumber: string): void {
    this.policyData = { contractNumber: contractNumber };

    this.successAddingHandler();
  }

  public navigateToAddPolicy(): void {
    this.changeStep(PolicySteps.CODE);
  }

  public back(): void {
    if (this.step === PolicySteps.CODE || this.step === PolicySteps.SUCCESS) {
      return this.router.navigate([this.bootstrapService.link.myInsurancePolicies]) as unknown as void;
    }

    if (this.step === PolicySteps.EMAIL && this.reverification) {
      return this.router.navigate([this.bootstrapService.link.myInsurancePolicies]) as unknown as void;
    }

    if (this.step === PolicySteps.EMAIL || this.step === PolicySteps.USER_DATA) {
      return this.changeStep(PolicySteps.CODE);
    }

    if (this.step === PolicySteps.PIN && this.emails.length > 1) {
      return this.changeStep(PolicySteps.EMAIL);
    }

    if (this.step === PolicySteps.PIN && this.emails.length <= 1 && this.reverification) {
      return this.router.navigate([this.bootstrapService.link.myInsurancePolicies]) as unknown as void;
    }

    if (this.step === PolicySteps.PIN && this.emails.length <= 1) {
      return this.changeStep(PolicySteps.CODE);
    }
  }

  private resetScroll(): void {
    this.zone.runOutsideAngular(() => requestAnimationFrame(
      () => this.document.scrollingElement.scrollTop = 0
    ));
  }

  private changeStep(step: PolicySteps): void {
    this.step = step;
    this.resetScroll();
    this.cdr.markForCheck();
  }

  private successAddingHandler(): void {
    const event: EventDigitalData = {
      category: 'travel_insurance',
      action: 'memberUpgraded',
      label: this.contractNumber,
      value: 1,
      timeStamp: new Date().toISOString(),
    };

    this.analyticsService.triggerAction(event);
    this.changeStep(PolicySteps.SUCCESS);
  }
}
