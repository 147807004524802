import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, filter, switchMap } from 'rxjs';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { UserService } from '@common/user/user.service';
import { CREDENTIAL_REGEXP } from '@pages/account/components/co-travelers/co-travelers.const';
import { wrapValidator } from '../field-error/field-error.utils';
import { codeToPattern } from '../phone/phone.utils';
import { ServerErrorsValidationService } from '../server-errors/server-errors-validation.service';
import { ServerValidationErrorResponse } from '../server-errors/server-errors.interface';
import { emailValidator, notBlankValidator, PROMO_CODE_REGEX, repeatPasswordGroupValidator } from '../utils/validators';
import { mapRegisterSourceToDto } from './register-form.mapper';
import { NON_SPACE_REGEX, PASSWORD_REGEX } from 'src/app/kit/password/password.const';
import { getAllFormErrors } from '../utils/form';
import { DigitalDataService } from '@common/analytics/digital-data.service';
import { DigitalData, User } from '@common/analytics/digital-data.interface';
import { TradedoublerService } from "@common/tradedoubler/tradedoubler.service";
import { TradedoublerEvent } from "@common/tradedoubler/tradedoublerRouteData";
import { ConfirmationDialogComponent } from "@kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "@kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { DialogService } from "@kit/dialog/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { RegistrationService } from "@common/registration/registration.service";
import { getCountryCode, Market } from "@common/language/language.const";
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService],
})
export class RegisterFormComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() title: string;
  @Input() description?: string;
  @Output() registered = new EventEmitter<string>();

  public registerForm!: UntypedFormGroup;
  public isMarketingConsentGlobal: boolean;
  public isAustrianMarket: boolean = getCountryCode(this.document.location.host) === Market.AT;

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly fb: UntypedFormBuilder,
    private readonly registrationService: RegistrationService,
    private readonly destroyRef: DestroyRef,
    private readonly analyticsService: AnalyticsService,
    private readonly userService: UserService,
    private readonly validationService: ServerErrorsValidationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly digitalDataService: DigitalDataService,
    private readonly tradedoublerService: TradedoublerService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean,
  ) {}

  get promoCodeFromUrl(): string {
    return this.activatedRoute?.snapshot?.queryParams?.pc || null;
  }

  get promoCodeControl(): AbstractControl {
    return this.registerForm.get('promoCode');
  }

  ngOnInit(): void {
    this.initForm();
    this.addFormControlsValidators();
    this.addFormControlsAsyncValidators();
    this.initMarketingConsentType();
    this.promoCodeValidationSubscription();
  }

  ngAfterViewInit(): void {
    if (this.isBrowser && this.promoCodeFromUrl) {
      this.updatePromoCodeControlValue(this.promoCodeFromUrl);
    }
  }

  ngAfterViewChecked(): void {
    this.configMarketingConsentPopInLinks();
  }

  public register(): void {
    this.registerForm.markAllAsTouched();

    if (!getAllFormErrors(this.registerForm)?.length) {
      const formValue = this.registerForm.getRawValue();

      this.registrationService.register(mapRegisterSourceToDto(formValue))
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError((err: ServerValidationErrorResponse) => {
            this.analyticsService.validationServerError(err, this.constructor.name);

            return this.validationService.handleServerError(err)
          }),
        )
        .subscribe(() => {
          this.triggerSignUpRequestedAction();
          this.tradedoublerService.sendTradedoublerAction(TradedoublerEvent.registration);
          this.registered.emit(formValue.email);
        });

      return;
    }

    this.analyticsService.validationError(this.registerForm, this.constructor.name);
  }

  private initForm(): void {
    this.registerForm = this.fb.group({
      email: [null],
      firstName: [null],
      lastName: [null],
      phone: [null],
      promoCode: [null, { updateOn: 'blur' }],
      password: [null],
      repeatPassword: [null],
      privacyPolicy: [null],
      marketingManagementGlobal: [null],
      marketingManagementInternal: [null],
      marketingManagementExternal: [null],
    });
  }

  private addFormControlsValidators(): void {
    const emailControl: AbstractControl = this.registerForm.get('email');
    const firstNameControl: AbstractControl = this.registerForm.get('firstName');
    const lastNameControl: AbstractControl = this.registerForm.get('lastName');
    const phoneControl: AbstractControl = this.registerForm.get('phone');
    const promoCodeControl: AbstractControl = this.registerForm.get('promoCode');
    const passwordControl: AbstractControl = this.registerForm.get('password');
    const repeatPasswordControl: AbstractControl = this.registerForm.get('repeatPassword');
    const privacyPolicyControl: AbstractControl = this.registerForm.get('privacyPolicy');

    emailControl.addValidators([
      wrapValidator(emailValidator, 'errors.CEAZ000_Pattern'),
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
    ]);
    firstNameControl.addValidators([
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
      wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
      wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern'),
    ]);
    lastNameControl.addValidators([
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
      wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
      wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern'),
    ]);
    phoneControl.addValidators([
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      wrapValidator(Validators.pattern(codeToPattern(this.userService.location)), 'errors.CEAZ000_Pattern'),
    ]);
    
    if (this.isAustrianMarket) {
      phoneControl.setValidators([
        wrapValidator(Validators.pattern(codeToPattern(this.userService.location)), 'errors.CEAZ000_Pattern'),
      ]);
    }

    promoCodeControl.addValidators([
      wrapValidator(Validators.pattern(PROMO_CODE_REGEX), 'errors.CEAZ010'),
      wrapValidator(Validators.maxLength(40), 'errors.CEAZ010'),
    ]);
    passwordControl.addValidators([
      wrapValidator(Validators.pattern(PASSWORD_REGEX), 'errors.CEAZ091'),
      wrapValidator(Validators.pattern(NON_SPACE_REGEX), 'errors.CEAZ058'),
      wrapValidator(Validators.minLength(8), 'errors.CEAZ091'),
      wrapValidator(Validators.maxLength(32), 'errors.CEAZ090'),
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
    ]);
    repeatPasswordControl.addValidators([
      wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
    ]);
    privacyPolicyControl.addValidators([
      wrapValidator(Validators.requiredTrue, 'errors.CEAZ011'),
    ]);

    this.registerForm.addValidators([
      wrapValidator(
        repeatPasswordGroupValidator(passwordControl, repeatPasswordControl),
        'errors.CEAZ026'
      ),
    ]);

    this.registerForm.addValidators(wrapValidator(
      repeatPasswordGroupValidator(passwordControl, repeatPasswordControl),
      'errors.CEAZ026'
    ));
  }

  private addFormControlsAsyncValidators(): void {
    this.registerForm.addAsyncValidators(this.validationService.createValidator());
    this.registerForm.get('email').addAsyncValidators([this.validationService.createValidator('email')]);
    this.registerForm.get('firstName').addAsyncValidators([this.validationService.createValidator('firstName')]);
    this.registerForm.get('lastName').addAsyncValidators([this.validationService.createValidator('lastName')]);
    this.registerForm.get('phone').addAsyncValidators([this.validationService.createValidator('phone')]);
    this.registerForm.get('promoCode').addAsyncValidators([this.validationService.createValidator('promoCode')]);
  }

  private promoCodeValidationSubscription(): void {
    this.promoCodeControl.valueChanges.pipe(
      filter(() => !this.promoCodeControl.invalid),
      switchMap((promoCode: string) =>
        this.registrationService.validatePromoCode(promoCode, this.validationService)
      ),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.updatePromoCodeInDigitalData(this.promoCodeControl.value);
    });
  }

  private initMarketingConsentType(): void {
    const market: string = getCountryCode(this.document.location.host);

    this.isMarketingConsentGlobal = ['DE', 'NL', 'AT'].includes(market);
  }

  private triggerSignUpRequestedAction(): void {
    this.analyticsService.triggerAction({
      category: 'member_account',
      action: 'signUpRequested',
      label: 'email',
      value: 1,
    });
  }

  private updatePromoCodeControlValue(promoCode: string): void {
    this.promoCodeControl.setValue(promoCode, { emitEvent: true });
    this.promoCodeControl.markAllAsTouched();
  }

  private updatePromoCodeInDigitalData(promoCode: string): void {
    try {
      const digitalData: DigitalData = this.digitalDataService.getDigitalData();
      const updatedDigitalData: DigitalData = {
        ...digitalData,
        user: {
          ...digitalData.user || ({} as User),
          voucherCode: promoCode,
        }
      }

      this.digitalDataService.putDigitalData(updatedDigitalData);
    } catch {
    }
  }

  private configMarketingConsentPopInLinks(): void {
    const oneTrustLink = this.document?.getElementById('mark-description');

    oneTrustLink?.setAttribute('style', 'text-decoration: underline');
    oneTrustLink?.addEventListener('click', (event: MouseEvent) => this.openMarketingPopIn(event));
  }

  private openMarketingPopIn(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('pages.REGISTRATION.MARKETING_MANAGEMENT_POP_IN'),
    });
  }
}
