<ng-container *let="(policies$ | async) as policies">
  <section *ngIf="policies?.length">
    <div class="shell">
      <div class="h4 m-h3">
        {{ 'pages.MY_INSURANCE.TITLES.PAST_POLICIES' | translate }}
      </div>
      <ng-container *ngFor="let policy of policies; let index = index">
        <ng-container *ngIf="index < shownPolicyCount">
          <ng-container [ngSwitch]="policy.status">
            <ng-container *ngSwitchCase="'FAILED'">
              <ng-container *ngTemplateOutlet="failedPolicy; context: { $implicit: policy }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'DEFERRED'">
              <ng-container *ngTemplateOutlet="deferredPolicy; context: { $implicit: policy }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'ACTIVE'">
              <ng-container *ngTemplateOutlet="pastPolicy; context: { $implicit: policy }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container *ngTemplateOutlet="pastPolicy; context: { $implicit: policy }"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <div class="buttons" *ngIf="policies.length > displayStep">
        <app-button icon="plus"
                    theme="bordered"
                    type="big-width"
                    (click)="showMorePolicies()"
                    *ngIf="policies.length > shownPolicyCount">
          {{'pages.MY_INSURANCE.ACTIONS.SHOW_MORE_PAST_POLICIES' | translate }}
        </app-button>
        <app-button icon="minus"
                    theme="bordered"
                    type="big-width"
                    (click)="showLessPolicies()"
                    *ngIf="shownPolicyCount > displayStep">
          {{ 'pages.MY_INSURANCE.ACTIONS.HIDE_PAST_POLICIES' | translate }}
        </app-button>
      </div>
    </div>
  </section>
</ng-container>

<ng-template let-policy #pastPolicy>
  <div class="past-card">
    <div (click)="onViewDetails(policy.number, policy?.openPolicyNumber)" class="card">
      <div class="card_icon">
        <app-icon class="icon" icon="form-edit" size="normal" theme="lighten"></app-icon>
      </div>
      <div class="card_body">
        <div class="card_field">
          <div class="t-large m-h6 weight-medium">{{policy.productName}}</div>
          <div class="card_date t-small m-t-small">
            <span class="block-title weight-bold">{{ 'pages.MY_INSURANCE.LABELS.DATES' | translate }}</span>
            {{policy.startDate | dateFormat : 'fullSpelled'}} - {{policy.endDate | dateFormat : 'fullSpelled'}}
          </div>
        </div>
        <div class="card_field row-direction t-small m-t-small">
          <span class="block-title weight-bold">{{ 'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate }} </span>
          {{policy.openPolicyNumber || policy.number}}
        </div>
      </div>
      <div class="card_button">
        <app-button (click)="deletePolicy(policy.number, $event)"
                    [disabled]="policy?.status === 'FAILED'"
                    size="s"
                    type="icon-button"
                    icon="thrash"
                    theme="bordered">
        </app-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-policy #deferredPolicy>
  <div class="failed-card">
    <div class="card">
      <div class="card_icon">
        <app-icon class="icon" icon="form-edit" size="normal" theme="lighten"></app-icon>
      </div>
      <div class="card_body">
        <div class="card_field">
          <div class="t-large m-h6 weight-medium">{{policy.productName}}</div>
          <div class="status status_deferred">
            <app-icon icon="clock" size="normal" theme="invert"></app-icon>
            <span>{{ 'pages.MY_INSURANCE.LABELS.STATUS_IN_REVIEW' | translate }}</span>
          </div>
        </div>
        <div class="card_field row-direction t-small m-t-small">
          <span class="block-title weight-bold">{{ 'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate }} </span>
          {{policy.openPolicyNumber || policy.number}}
        </div>
      </div>
      <div class="card_button">
        <app-button (click)="deletePolicy(policy.number, $event)"
                    size="s"
                    type="icon-button"
                    icon="thrash"
                    theme="bordered">
        </app-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-policy #failedPolicy>
  <div class="failed-card">
    <div class="card">
      <div class="card_icon">
        <app-icon class="icon" icon="form-edit" size="normal" theme="lighten"></app-icon>
      </div>
      <div class="card_body">
        <div class="card_field">
          <div class="t-large m-h6 weight-medium">{{policy.productName}}</div>
          <div class="status status_failed"
               [appTooltip]="'pages.MY_INSURANCE.TOOLTIP.NOT_VALID' | translate"
               [tooltipOffset]="5"
               [tooltipPosition]="'bottom'">
            <app-icon icon="warning" size="normal" theme="invert"></app-icon>
            <span>{{ 'pages.MY_INSURANCE.LABELS.STATUS_FAILED' | translate }}</span>
          </div>
        </div>
        <div class="card_field row-direction t-small m-t-small">
          <span class="block-title weight-bold">{{ 'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate }} </span>
          {{policy.openPolicyNumber || policy.number}}
        </div>
      </div>
      <div class="card_button">
        <app-button [disabled]="true"
                    size="s"
                    type="icon-button"
                    icon="thrash"
                    theme="bordered">
        </app-button>
      </div>
    </div>
  </div>
</ng-template>
