<div class="vouchers">
  <div class="section__shell shell">
    <ng-container *let="loungeVouchers$ | async as vouchers">
      <div *ngIf="vouchers?.length; else noVouchers" class="vouchers__container">
        <app-safe-html class="vouchers__text text-small" [content]="'pages.LOUNGE_VOUCHERS.ACTIONS.VIEW_LOUNGES_HREF' | translate"></app-safe-html>
        <div *ngFor="let voucher of vouchers, trackBy: trackBy" class="vouchers__items">
          <app-accordion theme="white" size="child">
            <ng-container accordionTitle>
              <div class="vouchers__title">
                <div class="vouchers__traveler t-normal m-t-normal">{{voucher.travelerName}}</div>
                <div class="vouchers__number weight-medium">{{voucher.vouchers.length}}</div>
              </div>
            </ng-container>
            <app-voucher-accordion [vouchers]="voucher.vouchers"
                                   [id]="voucher.travelerName"
                                   class="vouchers__accordion">
            </app-voucher-accordion>
          </app-accordion>
        </div>

        <div class="vouchers__buttons">
          <app-button size="l"
                      type="big-width"
                      theme="bordered"
                      class="vouchers__button"
                      id="lounge-vouchers-action-view-lounges"
                      (click)="viewLounges()">
            {{'pages.LOUNGE_VOUCHERS.ACTIONS.VIEW_LOUNGES_BUTTON' | translate}}
          </app-button>
          <app-button size="l"
                      type="big-width"
                      theme="bordered"
                      [appAemRouterLink]="bootstrapService.link.cotravelers"
                      class="vouchers__button"
                      id="lounge-vouchers-action-edit-cotravelers">
            {{'pages.LOUNGE_VOUCHERS.ACTIONS.EDIT_CO_TRAVELERS' | translate}}
          </app-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noVouchers>
  <div class="no-vouchers__container">
    <div class="no-vouchers__section">
      <img class="no-vouchers__image" src="assets/img/no-vouchers.svg">
      <app-safe-html class="no-vouchers__text text-small m-t-normal"
                     [content]="'pages.LOUNGE_VOUCHERS.DESCRIPTIONS.NO_VOUCHERS_TEXT' | translate">
      </app-safe-html>
    </div>
    <div class="vouchers__buttons">
      <app-button size="l"
                type="big-width"
                theme="bordered"
                class="vouchers__button"
                id="lounge-vouchers-action-view-lounges"
                (click)="viewLounges()">
        {{'pages.LOUNGE_VOUCHERS.ACTIONS.VIEW_LOUNGES_BUTTON' | translate}}
      </app-button>
    </div>

  </div>
</ng-template>
