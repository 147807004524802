<section class="content-block">
  <div class="shell">
    <h2 class="content-block__header h2 m-h2" *ngIf="data.h2">{{ data.h2 }}</h2>
    <h3 class="content-block__header h3 m-h3" *ngIf="data.h3">{{ data.h3 }}</h3>
    <h4 class="content-block__header h4 m-h4" *ngIf="data.h4">{{ data.h4 }}</h4>
    <h5 class="content-block__header h5 m-h5" *ngIf="data.h5">{{ data.h5 }}</h5>

    <app-safe-html
      class="content-block__text t-small m-t-normal"
      *ngIf="data.text"
      [content]="data.text"
      [style.color]="data.colourText"
    ></app-safe-html>

    <app-safe-html
      class="content-block__text t-tiny m-t-small"
      *ngIf="data.smallText"
      [content]="data.smallText"
      [style.color]="data.colourSmallText"
    ></app-safe-html>

    <app-safe-html
      class="content-block__text t-large m-t-normal"
      *ngIf="data.largeText"
      [content]="data.largeText"
      [style.color]="data.colourLargeText"
    ></app-safe-html>
  </div>
</section>
