<app-dialog-wrapper class="survey">
  <div dialogTitle class="survey__title">
    <img class="survey__avatar"
         *ngIf="data.image.imagePath"
         [src]="data.image.imagePath"
         [appImgAlt]="data.image.altText"
         [appImgAltData]="data">
    <h5 class="zero-margin h5 m-h3">{{ data.title }}</h5>
  </div>
  <ng-container dialogContent>
    <p *ngIf="data.text" class="t-large m-t-normal">{{ data.text }}</p>

    <app-safe-html class="survey__code"  *ngIf="data.code" [content]="data.code"></app-safe-html>
  </ng-container>
</app-dialog-wrapper>
