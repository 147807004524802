<section>
  <div class="shell" *ngIf="(policies$ | async) as policies">
    <ng-container *ngIf="policies?.length; else noActivePolicies">
      <h4 class="h4 m-h3">
        {{ 'pages.MY_INSURANCE.TITLES.CURRENT_N_FUTURE_POLICIES' | translate }}
      </h4>
      <div class="cards">
        <ng-container *ngFor="let policy of policies">
          <ng-container [ngSwitch]="policy.status">
            <ng-container *ngSwitchCase="'FAILED'">
              <ng-container *ngTemplateOutlet="failedPolicy; context: { $implicit: policy }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'DEFERRED'">
              <ng-container *ngTemplateOutlet="deferredPolicy; context: { $implicit: policy }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'ACTIVE'">
              <ng-container *ngTemplateOutlet="activePolicy; context: { $implicit: policy }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container *ngTemplateOutlet="activePolicy; context: { $implicit: policy }"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="add-button">
        <app-button [appAemRouterLink]="bootstrapService.link.insuranceAddExistingPolicy"
                    theme="bordered"
                    type="big-width">
          <app-icon class="icon" icon="plus" size="normal" theme="lighten"></app-icon>
          {{ 'pages.MY_INSURANCE.ACTIONS.ADD_INSURANCE_POLICY' | translate }}
        </app-button>
      </div>
    </ng-container>
  </div>
</section>

<ng-template #noActivePolicies>
  <h4 class="h4 m-h3">{{ 'pages.MY_INSURANCE.TITLES.CURRENT_OR_FUTURE_POLICIES' | translate }}</h4>
  <p>{{ 'pages.MY_INSURANCE.DESCRIPTIONS.NO_ACTIVE_POLICIES' | translate }}</p>
</ng-template>

<ng-template let-policy #activePolicy>
  <div class="card">
    <div class="h5 m-h3 card_header">
      <span [class.breaked_title]="isDEMarket" class="card_header_text">
        {{ 'pages.MY_INSURANCE.TITLES.INSURANCE_POLICY' | translate }}
      </span>
    </div>
    <div class="card_body">
      <div class="card_table">
        <div class="row">
          <div class="row_title h6 h4">
            <app-icon class="icon t-normal m-t-normal" icon="form" size="large" theme="lighten"></app-icon>
            {{ 'pages.MY_INSURANCE.LABELS.POLICY_NAME' | translate }}
          </div>
          <div class="t-normal m-t-normal">{{policy.productName}}</div>
        </div>
        <div class="row">
          <div class="row_title h6 h4">
            <app-icon class="icon t-normal m-t-normal" icon="form" size="large" theme="lighten"></app-icon>
            {{ 'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate }}
          </div>
          <div class="t-normal m-t-normal">{{ policy.openPolicyNumber || policy.number}}</div>
        </div>
        <div class="row">
          <div class="row_title h6 h4">
            <app-icon class="icon t-normal m-t-normal" icon="form" size="large" theme="lighten"></app-icon>
            {{ 'pages.MY_INSURANCE.LABELS.DATES' | translate }}
          </div>
          <div class="t-normal m-t-normal">
            {{policy.startDate | dateFormat : 'fullSpelled'}} - {{policy.endDate | dateFormat : 'fullSpelled'}}
          </div>
        </div>
      </div>
      <div class="card_buttons">
        <app-button (click)="onViewDetails(policy.number, policy.openPolicyNumber)"
                    type="full-width"
                    size="m">
          {{ 'pages.MY_INSURANCE.ACTIONS.VIEW_POLICY_DETAILS' | translate }}
        </app-button>
        <app-button (click)="deletePolicy(policy.number)"
                    theme="bordered"
                    icon="thrash"
                    type="full-width"
                    size="m">
          {{ 'pages.MY_INSURANCE.ACTIONS.REMOVE_POLICY' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-policy #deferredPolicy>
  <div class="card">
    <div class="h5 m-h3 card_header deferred">
      <span class="card_header_text">{{ 'pages.MY_INSURANCE.TITLES.INSURANCE_POLICY_DEFERRED' | translate }}</span>
      <div class="card_header_status status-deferred">
        <app-icon icon="clock" size="normal" theme="invert"></app-icon>
        <span>{{ 'pages.MY_INSURANCE.LABELS.STATUS_IN_REVIEW' | translate }}</span>
      </div>
    </div>
    <div class="card_body">
      <div class="card_table">
        <div class="row">
          <div class="row_title h6 h4">
            <app-icon class="icon t-normal m-t-normal" icon="form" size="large" theme="lighten"></app-icon>
            {{ 'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate }}
          </div>
          <div class="t-normal m-t-normal">{{ policy.openPolicyNumber || policy.number}}</div>
        </div>
        <div class="row one-colum">
          <div class="row_title h6 h4">
            <app-icon class="icon t-normal m-t-normal" icon="warning" size="large" theme="lighten"></app-icon>
          </div>
          <div class="t-normal m-t-normal">
            <app-safe-html [content]="'pages.MY_INSURANCE.DESCRIPTIONS.POLICY_DEFERRED' | translate"></app-safe-html>
          </div>
        </div>
      </div>
      <div class="card_buttons">
        <app-button (click)="deletePolicy(policy.number)"
                    theme="bordered"
                    icon="thrash"
                    type="full-width"
                    size="m">
          {{ 'pages.MY_INSURANCE.ACTIONS.REMOVE_POLICY' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-policy #failedPolicy>
  <div class="card">
    <div class="h5 m-h3 card_header failed">
      <span class="card_header_text" [class.breaked_title]="isDEMarket">
        {{ 'pages.MY_INSURANCE.TITLES.INSURANCE_POLICY_FAILED' | translate }}
      </span>
      <div class="card_header_status status-failed">
        <app-icon icon="warning" size="normal" theme="invert"></app-icon>
        <span>{{ 'pages.MY_INSURANCE.LABELS.STATUS_FAILED' | translate }}</span>
      </div>
    </div>
    <div class="card_body">
      <div class="card_table">
        <div class="row">
          <div class="row_title h6 h4">
            <app-icon class="icon t-normal m-t-normal" icon="form" size="large" theme="lighten"></app-icon>
            {{ 'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate }}
          </div>
          <div class="t-normal m-t-normal">{{ policy.openPolicyNumber || policy.number}}</div>
        </div>
        <div class="row one-colum">
          <div class="row_title h6 h4">
            <app-icon class="icon t-normal m-t-normal" icon="warning" size="large" theme="lighten"></app-icon>
          </div>
          <div class="t-normal m-t-normal">
            <app-safe-html [content]="'pages.MY_INSURANCE.DESCRIPTIONS.POLICY_FAILED' | translate"></app-safe-html>
          </div>
        </div>
      </div>
      <div class="card_buttons">
        <app-button [disabled]=true
                    theme="bordered"
                    icon="thrash"
                    type="full-width"
                    size="m">
          {{ 'pages.MY_INSURANCE.ACTIONS.REMOVE_POLICY' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</ng-template>
