<div class="form-field select"
     [class.form-field_focused]="expanded"
     [class.form-field_disabled]="disabled"
     (activeZoneChange)="changeActiveState($event)">
  <div class="select_wrapper" (click)="toggleOption()">
    <div class="select_search-icon" (click)="input.focus()">
      <app-icon class="icon"
                icon="search"
                size="large"
                theme="lighten">
      </app-icon>
    </div>
    <input #input
           class="select_input text-small m-t-normal"
           [placeholder]="placeholder"
           (blur)="onBlur()"
           [formControl]="searchControl">
    <div class="select__action" (click)="input.focus()">
      <app-icon *ngIf="!disabled && clearButton && selectedView"
                (click)="clear($event)"
                class="select__action-icon icon"
                icon="close"
                size="large"
                theme="lighten">
      </app-icon>
      <app-icon class="select__action-icon select__action-toggle icon"
                icon="chevron-down"
                size="large"
                theme="lighten">
      </app-icon>
    </div>
  </div>

  <div class="select_options text-small m-t-normal" *ngIf="expanded">
    <ng-container *ngIf="options?.length; else emptyPlaceholder">
      <div *ngFor="let option of filteredOptions$ | async"
           class="select_options-item"
           [class.active]="option === selectedOption"
           (click)="select(option)">
        <div class="select_options-item-content">
          {{ option[viewKey] }}
        </div>
      </div>
    </ng-container>

    <ng-template #emptyPlaceholder>
      <div class="select_options-item-empty">
        {{ 'forms.CREATE_TRIP.PLACEHOLDERS.EMPTY_SEARCH_RESULT' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="expanded"
     class="select_toggle-area">
</div>
