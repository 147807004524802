import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';

@Component({
  selector: 'app-insurance-add-succeeded',
  templateUrl: './insurance-add-succeeded.component.html',
  styleUrls: ['./insurance-add-succeeded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceAddSucceededComponent {
  @Input() policyCode: string;
  @Input() reverification: boolean;

  public showViewPolicyButton = false;
  public deferred = this.apiPolicyService.lastVerifiedPolicyDeferred;
  public openPolicy = this.apiPolicyService.lastVerifiedPolicyOpenPolicy;

  constructor(
    private readonly router: Router,
    private readonly apiPolicyService: ApiInsurancePolicyService,
    public readonly bootstrapService: BootstrapService
  ) { }

  public onViewPolicy(): void {
    //TO DO: return cursor pointer on hover
    if (!this.showViewPolicyButton) return;

    this.router.navigateByUrl(
      setRouterData(
        this.bootstrapService.link.insurancePolicy,
        { policyId: btoa(this.policyCode) }
      ));
  }
}
